<template>
  <div id="app">
    <div class="imageView">
      <div class="imageViewMargin">
        <img id="firstPicture" src="../../assets/defaultPortrait.png" alt />
      </div>
      <!-- 文字 -->
      <div>
        <div class="wrapperFlex">
          <div class="wrapperFlexLeft">
            <div>姓</div>
            <div>名 ：</div>
          </div>
          <div id="userName" class="wrapperRight">{{ obj.userName || "" }}</div>
        </div>
        <div class="wrapperFlex">
          <div class="wrapperFlexLeft">
            <div>岗</div>
            <div>位 ：</div>
          </div>
          <div id="station" class="wrapperRight">{{ obj.station || "" }}</div>
        </div>
        <div class="wrapperFlex">
          <div class="wrapperFlexLeft">
            <div>岗</div>
            <div>位</div>
            <div>级</div>
            <div>别 ：</div>
          </div>
          <div id="stationLevel" class="wrapperRight">{{ obj.stationLevel || "" }}</div>
        </div>
        <div class="wrapperFlex">
          <div class="wrapperFlexLeft">
            <div>证</div>
            <div>书</div>
            <div>编</div>
            <div>号 ：</div>
          </div>
          <div id="certificateNo" class="wrapperRight">{{ obj.certificateNo || "" }}</div>
        </div>
      </div>
      <div style="
          box-sizing: border-box;
          padding: 0 24px 0 36px;
          overflow: hidden;
          position: relative;
          margin-top: 18.2px;
        ">
        <div class="wrapperErWei">
          <img id="secondPicture" :src="obj.secondPicture" alt />
        </div>
        <div class="wrapperErWeiRight">
          <div class="wrapperErWeiRightFlex" style="margin-bottom: 3px">
            <div>发证单位 ：</div>
            <div>中国汽车流通协会</div>
          </div>
          <div class="wrapperErWeiRightFlex">
            <div>发证日期 ：</div>
            <div id="ateIssueTime">{{ obj.ateIssueTime || "" }}</div>
          </div>
        </div>
      </div>
    </div>
    <img class="image"
      src="https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation/job_cert_reverse_20220309.png"
      alt />
    <img class="back_img" @click="call_back" src="../../assets/backs.png" alt="">
  </div>
</template>

<script>
import QRCode from "qrcode";
export default {
  // 组件名称
  name: "demo",
  data() {
    return {
      obj: {
        userName: "车萍萍",
        station: "商用车鉴定评估师",
        stationLevel: "中级",
        certificateNo: "20150101235959999001",
        firstPicture: "",
        secondPicture: "",
        ateIssueTime: "2015-01-01",
      },
      url: "https://h5.chinaucv.com/defaultEva",
    };
  },
  mounted() {
    document.title = "证书示例";
    QRCode.toDataURL(this.url, {
      //这个this.url就是你扫码后要跳转的地址
      version: 7, //版本号
      errorCorrectionLevel: "Q", //容错率,(建议选较低)更高的级别可以识别更模糊的二维码，但会降低二维码的容量
      width: 280, //设置二维码图片大小
      height: 280,
    })
      .then((url) => {
        console.log(window.location.href)
        // console.log(url); //这个url是二维码生成地址，也就是相当于图片地址
        this.obj.secondPicture = url; //赋值给变量。用在img的src属性上
      })
      .catch((err) => {
        console.error(err);
      });
  },
  methods: {
    call_back() { this.$router.go(-1); },
  },
};
</script>

<style scoped>
body {
  width: 100%;
  background: #f8f8f8;
}

.image {
  /* width: 7.5rem; */
  width: 100%;
  height: 593px;
}

.imageView {
  margin-bottom: 10px;
  width: 100%;
  height: 593px;
  background: url("https://vehicle-library.oss-cn-beijing.aliyuncs.com/cvbd-icon/evaluation/job_cert_font_20220309.png") center center no-repeat;
  background-size: 100%;
  overflow: hidden;
}

.imageViewMargin {
  width: 146px;
  height: 204px;
  margin: 140px auto 20px;
  position: relative;
}

.imageViewMargin>img {
  width: 100%;
  height: 100%;
}

.wrapperFlex {
  width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  padding: 5px 0;
}

.wrapperFlexLeft {
  display: flex;
  justify-content: space-between;
  font-size: 15px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #241915;
  width: 95px;
}

.wrapperRight {
  flex: 1;
  font-size: 15px;
  font-family: SourceHanSansCN-Medium, SourceHanSansCN;
  font-weight: 500;
  color: #241915;
}

.wrapperErWei {
  width: 76px;
  height: 76px;
  float: left;
}

.wrapperErWei>img {
  width: 100%;
  height: 100%;
}

.wrapperErWeiRight {
  font-size: 13px;
  font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  font-weight: 400;
  color: #241915;
  position: absolute;
  bottom: 0;
  right: 24px;
}

.wrapperErWeiRightFlex {
  display: flex;
}

.back_img {
  width: 40px;
  height: 40px;
  position: fixed;
  right: 10px;
  bottom: 150px;
  z-index: 99999999;
}
</style>
